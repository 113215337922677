<template>
  <CardContent
    class="card-content-verification"
    title="Enter your <br> verification code"
    :icon="require('@/assets/img/verify.png')"
    :back="backHandler"
  >
    <form
      class="login-form"
      novalidate
      @submit.prevent="onSubmit"
      @keydown.enter="onSubmit"
    >
      <div class="fieldset">
        <FormValidateProvider
          :validate="needValidate"
          @validate="validationHandler"
        >
          <ValidateProvider
            :rules="['required', 'one-digit']"
            v-for="(_, idx) of code"
            :key="`code:${idx}`"
          >
            <AppInput
              name="login-email"
              class="one-digit"
              v-model="code[idx]"
              type="tel"
              :id="`code-${idx}`"
              :data-idx="idx"
              :use-error-message="false"
              :max-length="1"
              :setRef="setRef"
              :parentError="codeError ? 'true' : null"
              @keyup="next"
              @focus="focusHandler"
            />
          </ValidateProvider>
        </FormValidateProvider>
      </div>

      <transition name="fade">
        <span class="app-input-description error" v-if="codeError">
          Please, enter valid verification code
        </span>
      </transition>

      <AppButton
        type="submit"
        color="green"
        size="fluid"
        :setRef="setButtonRef"
        :loading="loading"
      >
        Continue
      </AppButton>
    </form>
  </CardContent>
</template>

<script>
import FormValidateProvider from "@/components/UI/FormValidateProvider";
import ValidateProvider from "@/components/UI/ValidateProvider";
import AppInput from "@/components/UI/AppInput";
import AppButton from "@/components/UI/AppButton";
import { useForm } from "@/mixins/useForm";
import CardContent from "@/components/Сard/CardContent";
import { isBackspace, isSymbol } from "@/utilles/isSymbol";
export default {
  name: "AuthCode",
  mixins: [useForm],
  components: {
    CardContent,
    AppButton,
    AppInput,
    ValidateProvider,
    FormValidateProvider,
  },
  data() {
    return {
      loading: false,
      codeError: false,
      codeNumbersCount: 4,
      code: ["", "", "", ""],
      digitsRefs: [],
      focused: 0,
      buttonRef: null,
    };
  },

  watch: {
    code: {
      handler() {
        this.codeError = false;
      },
      deep: true,
    },
  },

  mounted() {
    const firstDigit = this.digitsRefs[0];
    if (firstDigit) {
      setTimeout(() => {
        firstDigit.focus();
      }, 500);
    }
  },
  methods: {
    focusHandler({ target }) {
      const { idx = 0 } = target.dataset;
      this.focused = parseInt(idx);
    },

    setButtonRef(button) {
      if (!this.buttonRef && button) {
        this.buttonRef = button;
      }
    },
    backHandler() {
      this.$router.back();
    },

    next({ key }) {
      if (!this.digitsRefs) {
        return;
      }

      const backspace = isBackspace(key);
      const symbol = isSymbol(key);

      if (backspace) {
        if (this.focused > 0) {
          const newFocused = (this.focused -= 1);

          this.digitsRefs[newFocused].focus();
          this.focused = newFocused;
        }
      }

      if (this.focused >= this.digitsRefs.length - 1) {
        this.focused = -1;

        if (this.buttonRef) {
          this.buttonRef?.focus();
        }

        return;
      }

      if (symbol) {
        const newFocused = (this.focused += 1);

        this.digitsRefs[newFocused].focus();
        this.focused = newFocused;
      }
    },

    async onSubmit() {
      this.loading = true;
      const status = await this.$store.dispatch(
        "verifyCode",
        this.code.join("")
      );

      this.loading = false;

      this.codeError = !status;
      status && (await this.$router.push({ path: "/home" }));
    },

    setRef(el) {
      if (el) {
        if (this.digitsRefs.length < this.code.length) {
          this.digitsRefs.push(el);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fieldset {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;

  @include media(sm) {
    grid-gap: 20px;
  }
}
</style>
