import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/style/global.scss";
import { Api } from "@/api/Api";
import { storage } from "@/storage/storage";
import { createAxios } from "@/plugins/axios";
import "./registerServiceWorker";
const { v4: uuidv4 } = require("uuid");

const token = storage.get("token");
const client_uuid = storage.get("client_uuid") || uuidv4();

if (client_uuid) {
  store.commit("setClientUuid", client_uuid);
}

if (token) {
  store.commit("setToken", token);
  store.commit("setAuthStatus");
}

async function logout() {
  store.commit("setAuthenticated", false);
  await router.push("/auth");
}

const $axios = createAxios(store, { logout });

export const api = new Api($axios);

window.call = {
  alertData: {
    from: "",
  },

  showCallAlert() {
    store.commit("setCallAlertShown", true);
  },

  hideCallAlert() {
    store.commit("setCallAlertShown", false);
  },

  showCall() {
    store.commit("setCallModalOpened", true);
  },

  hideCall() {
    store.commit("setCallModalOpened");
  },
};

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.isAuthenticated;

  if (!isAuthenticated && to.fullPath !== "/auth") {
    if (to.fullPath === "/auth/verification") {
      next();

      return;
    }

    next({ path: "/auth" });
  } else {
    if (to.fullPath === "/") {
      next({ path: "/home" });
    }

    next();
  }
});

router.afterEach(({ path }) => {
  store.commit("setCurrentRoute", path);
});

createApp(App).use(store).use(router).mount("#app");
