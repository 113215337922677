<template>
  <button class="icon-button">
    <img :src="icon" alt="" />
  </button>
</template>

<script>
export default {
  name: "IconButton",
  props: { icon: { type: String, required: true } },
};
</script>

<style scoped lang="scss">
.icon-button {
  width: 50px;
  height: 50px;
  background: transparent;
  position: relative;
  border: 1px solid var(--stroke-grey);
  border-radius: 13px;
  box-shadow: 0 5px 15px rgba(#000, 0);
  cursor: pointer;
  @media (any-hover: hover) {
    transition: 0.3s;

    &:hover {
      border-color: transparent;
      background: #fff;
      box-shadow: 0 8px 15px rgba(#000, 0.07);
      transform: scale(1.01);
    }
  }
  img {
    width: 25%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
  }
}
</style>
