<template>
  <label :for="id" class="app-input-label" :id="`label-${id}`">
    <label :for="id" class="app-input-label text-label" v-if="label">
      {{ label }}
    </label>

    <div class="input-phone-select">
      <PhoneCodeSelect
        v-if="fieldType === 'phone'"
        @select="(code) => (this.countryCode = code)"
        ref="codeRef"
      />
    </div>

    <input
      v-bind="$attrs"
      class="app-input"
      :style="inputStyles"
      :class="{ error: hasError }"
      :value="val"
      :ref="refHandler"
      :id="id"
      @focusout="touched = true"
      @change="changeHandler"
      @input="changeHandler"
      @keydown="keyupHandler"
    />

    <template
      v-if="(useErrorMessage && parentError) || (useErrorMessage && hasError)"
    >
      <transition name="fade">
        <span class="app-input-description error">
          {{ parentError || error.value }}
        </span>
      </transition>
    </template>

    <template v-else-if="description">
      <transition name="fade">
        <span class="app-input-description">
          {{ description }}
        </span>
      </transition>
    </template>
  </label>
</template>

<script>
import { isBackspace, isSymbol } from "@/utilles/isSymbol";
import PhoneCodeSelect from "@/components/UI/PhoneSelect";

export default {
  name: "AppInput",
  inheritAttrs: false,
  components: { PhoneCodeSelect },
  props: {
    fieldType: {
      type: String,
      validator: (t) => ["email", "phone", "digit"].indexOf(t) !== -1,
      default: "email",
    },
    parentError: { type: [String, null], default: null },
    id: { type: String, required: true },
    label: { type: String, required: false },
    description: { type: String, required: false },
    useErrorMessage: { type: Boolean, default: true },
    maxLength: { type: [Number, null], default: null },
    setRef: { type: Function, required: false },
    modelValue: {
      type: [String],
      default: "",
    },
  },

  data() {
    return {
      touched: false,
      countryCodeWith: 0,
      countryCode: null,
      inputRef: null,
    };
  },

  inject: ["validateChange", "validateFocusout", "error"],

  computed: {
    val() {
      if (!this.countryCode || !this.fieldType === "phone") {
        return this.modelValue;
      }

      return this.getNumberWithoutCode(this.modelValue);
    },

    hasError() {
      if (!this.error && !this.parentError) {
        return false;
      }

      return this.parentError || typeof this.error.value === "string";
    },

    inputStyles() {
      const style = {};
      let cssPadding = 0;

      if (this.inputRef) {
        cssPadding =
          getComputedStyle(this.inputRef).getPropertyValue("--input-x") || 0;
      }

      if (this.countryCodeWith) {
        style.paddingLeft = `calc(${cssPadding} + ${this.countryCodeWith}px + 4px) `;
      }

      return style;
    },
  },

  watch: {
    modelValue(value) {
      if (this.fieldType === "phone") {
        this.validateChange(this.getNumberWithoutCode(value));
      }
      this.validateChange(value);
    },

    countryCode() {
      this.$emit("update:modelValue", "");
      this.$nextTick(this.setCountryCodeWith);
    },
  },

  methods: {
    setCountryCodeWith() {
      this.countryCodeWith = this.$refs.codeRef?.width;
    },

    getNumberWithoutCode(num) {
      return num.slice(this.countryCode.length);
    },

    refHandler(el) {
      this.inputRef = el;
      if (el && this.setRef) {
        this.setRef(el);
      }
    },

    keyupHandler(evt) {
      if (this.maxLength && this.modelValue.length === this.maxLength) {
        if (isSymbol(evt.key)) {
          this.$emit("update:modelValue", evt.key);
        }

        if (isBackspace(evt.key)) {
          this.$emit("update:modelValue", "");
        }

        evt.preventDefault();
        return false;
      }
    },

    changeHandler({ target }) {
      let value = target.value;

      if (this.countryCode && this.fieldType === "phone") {
        value = `${this.countryCode}${value}`;
      }
      this.$emit("update:modelValue", value);

      if (this.validateChange) {
        let val = value;

        if (this.fieldType === "phone") {
          val = this.getNumberWithoutCode(value);
        }

        this.validateChange(val);
      }
    },

    handleOutside({ target }) {
      if (!target.closest(`#label-${this.id}`)) {
        const value = this.val;
        this.touched && this.validateFocusout(value);
      }
    },
  },

  mounted() {
    this.setCountryCodeWith();

    document.addEventListener("click", this.handleOutside);
  },

  beforeUnmount() {
    document.removeEventListener("click", this.handleOutside);
  },
};
</script>

<style lang="scss">
$input-x: 1.2rem;
@include shake();
.app-input-label {
  display: block;
}
:root {
  --input-height: 3.4rem;
  --input-x: 1.2rem;
}
.app-input {
  width: 100%;
  height: var(--input-height);
  background: var(--field-grey);
  border: 1px solid transparent;
  outline: none;
  border-radius: var(--border-radius);
  padding-left: $input-x;
  padding-right: $input-x;
  font-size: 0.9rem;
  transition: 0.35s;
  line-height: 1;
  box-shadow: #{shadow(rgba(#000, 0))};

  &.one-digit {
    text-align: center;

    @include media(sm) {
      font-size: 20px;
    }
  }
  &:focus {
    box-shadow: #{shadow(rgba(var(--green), 0.1), 30px)};
    border-color: var(--green);
  }

  &.error {
    border-color: var(--danger);
    background-color: var(--danger-light);
    color: var(--danger);
    @include shakeStart();
  }

  &-label {
    position: relative;
    &.text-label {
      font-size: 0.86rem;
      font-weight: 700;

      margin-bottom: 0.9em;
      display: block;
    }
  }

  &-description {
    font-size: 0.8rem;
    margin-top: 8px;
    display: block;
    margin-left: $input-x;

    &.error {
      color: var(--danger);
    }
  }
}

.input-phone-select {
  position: absolute;
  top: 38px;
  left: $input-x;
  z-index: 9999;
  width: max-content;

  @include media(xxxl) {
    top: 50px;
  }
}
</style>
