export default {
  state: {
    callStarted: false,
    declineSocketCallback: null,
    unlockFromSocket: null,
    callAlertShown: false,
    callModalOpened: false,
  },

  getters: {
    callStarted({ callStarted }) {
      return callStarted;
    },

    decline({ declineSocketCallback }) {
      return declineSocketCallback;
    },

    unlockFromSocket({ unlockFromSocket }) {
      return unlockFromSocket;
    },

    callAlertShown: ({ callAlertShown }) => callAlertShown,
    callModalOpened: ({ callModalOpened }) => callModalOpened,
  },

  mutations: {
    setUnlockFromSocket(state, cb) {
      state.unlockFromSocket = cb;
    },
    setCallStarted(state, status) {
      state.callStarted = status;
    },
    setDeclineSocketCallback(state, cb) {
      state.declineSocketCallback = cb;
    },

    setCallAlertShown(state, status) {
      state.callAlertShown = status;
    },

    setCallModalOpened(state, status) {
      state.callModalOpened = status;
    },
  },

  actions: {
    declineCallFromUi({ getters }) {
      const { decline } = getters;

      if (decline) {
        decline();
      }
    },

    declineFromSocket({ commit }) {
      commit("setCallStarted", false);
      commit("setUnlockFromSocket", null);
    },

    startCall({ commit }) {
      commit("setCallStarted", true);
    },

    async unlockFromSocket({ getters }) {
      if (getters.unlockFromSocket) {
        await getters.unlockFromSocket();
      }
    },
  },
};
