import { apiRoutes } from "@/api/api-routes";
const platform = require("platform");
import { version } from "../../package";

export class Api {
  constructor(http) {
    this.http = http;
  }

  init(client_uuid) {
    return new Promise((resolve, reject) => {
      this.http
        .post(apiRoutes.init.route(), {
          device_uuid: client_uuid,
          manufacturer: platform.manufacturer || "",
          model: "",
          platform: "safari" in window ? "safari-browser" : "mozilla-browser",
          os_version: platform.os.toString() || "",
          app_version: version || "",
        })
        .then(resolve)
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  async sendCode(email) {
    try {
      const { data: res } = await this.http.post(apiRoutes.sendCode.route(), {
        email_or_number: email,
      });

      return !!res;
    } catch (err) {
      throw new Error(err);
    }
  }

  async verifyCode(email, code) {
    try {
      const { data } = await this.http.post(apiRoutes.verifyCode.route(), {
        email_or_number: email,
        code: code,
      });

      if (!data) {
        return false;
      }

      return data;
    } catch (err) {
      return { error: true, errorText: err };
    }
  }

  async fetchUserDetails() {
    try {
      const { data } = await this.http.get(apiRoutes.userDetails.route(), {});

      return data;
    } catch (err) {
      return err;
    }
  }

  async login(uuid) {
    try {
      return await this.http.post(apiRoutes.login.route(), {
        device_uuid: uuid,
      });
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async unlock(id, token) {
    try {
      return await this.http.post(
        apiRoutes.unlock.route(id),
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
    } catch (err) {
      console.error(err);
    }
  }

  unlockBySession(sessionId) {
    if (!sessionId) {
      return null;
    }

    return new Promise(async (resolve, reject) => {
      try {
        await this.http.post(apiRoutes.unlockBySession.route(sessionId), {});
        resolve("ok");
      } catch (e) {
        reject(e);
      }
    });
  }

  async logout(uuid) {
    try {
      return await this.http.post(apiRoutes.logout.route(), {
        device_uuid: uuid,
      });
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async toggleWorkStatus(status) {
    const numericStatus = status === "stopped" ? 0 : 1;
    try {
      return this.http.post(apiRoutes.toggleWorkStatus.route(numericStatus));
    } catch (err) {
      console.log(err);
      return false;
    }
  }
}
