<template>
  <CardContent
    class="card-content-home"
    title="Welcome"
    :icon="require('@/assets/img/home.png')"
  >
    <div class="doors-wrapper">
      <h2>My doors</h2>
      <ul class="doors-list" v-if="locks.length">
        <li
          class="doors-list--item"
          v-for="{
            building_description,
            lock_uuid,
            description,
            status,
          } in locks"
          :key="lock_uuid"
        >
          <Door
            :title="building_description"
            :description="description"
            :status="status"
            @unlock="unlockHandler(lock_uuid)"
          />
        </li>
      </ul>

      <div class="no-doors" v-else>
        <div><img src="@/assets/img/icons/door.svg" alt="" /></div>
        <p>No doors found</p>
      </div>
    </div>
  </CardContent>
</template>

<script>
import CardContent from "@/components/Сard/CardContent";
import { mapGetters } from "vuex";
import Door from "@/components/Door/Door";
export default {
  name: "Home",
  components: { Door, CardContent },
  computed: {
    ...mapGetters(["doors", "locks"]),
  },

  methods: {
    unlockHandler(id) {
      this.$store.dispatch("unLock", id);
    },
  },

  mounted() {
    this.$store.dispatch("fetchLocks");
  },
};
</script>

<style lang="scss">
.doors-wrapper {
  display: flex;
  flex-direction: column;
}
.no-doors {
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  padding-top: 40px;
  padding-bottom: 40px;

  @include media(md) {
    padding: 100px 0;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  img {
    width: 50px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    color: #cecece;
  }
}
.doors-wrapper {
  margin-top: 100px;
  width: 100%;

  h2 {
    margin-bottom: 28px;

    @include media($desktop) {
      margin-bottom: 40px;
    }
  }
}

.doors-list {
  list-style: none;
  padding: 0;
  display: grid;
  flex-wrap: wrap;

  grid-template-columns: 1fr;
  grid-gap: 15px;

  @include media($desktop) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }
}
</style>
