<template>
  <AppModal
    :opened="opened"
    :usePadding="false"
    :width="600"
    :bg="false"
    :close-on-backdrop="false"
    @close="onClose"
  >
    <div class="video-modal">
      <div class="video-wrapper">
        <div class="video-spinner">
          <Spinner />
        </div>

        <video src="#"></video>
      </div>

      <div class="call-toolbar">
        <div class="call-toolbar--status">
          <Spinner
            class="call-toolbar--spinner"
            v-if="lockStatus === 'unlocking'"
          />
          <img class="call-toolbar--icon" :src="statusIcon" alt="" v-else />
          <span class="call-toolbar--text">{{ statusText }}</span>
        </div>

        <button
          class="unlock-button"
          :disabled="!canUnlock"
          type="button"
          @click="unlockHandler"
        >
          <span class="unlock-button-text">Unlock the door</span>
          <span class="unlock-button-icon">
            <img src="@/assets/img/icons/lock.svg" alt="Lock" />
          </span>
        </button>

        <button class="end-call" type="button" @click="decline">
          <img src="@/assets/img/icons/end-call.svg" alt="" />
        </button>
      </div>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "@/components/UI/AppModal";
import Spinner from "@/components/Door/Spinner";
export default {
  name: "CallModal",
  components: { Spinner, AppModal },
  emits: { close: null, unlock: null, decline: null },
  props: {
    lockStatus: { type: String, required: true },
  },

  computed: {
    opened() {
      return this.$store.getters.callModalOpened;
    },
    canUnlock() {
      return this.lockStatus === "locked";
    },

    statusText() {
      return `Doors ${this.lockStatus} ${
        this.lockStatus === "unlocking" ? "..." : ""
      }`;
    },

    statusIcon() {
      if (this.lockStatus === "locked") {
        return "@/assets/img/secure-on-fill.svg";
      }
      if (this.lockStatus === "unlocked") {
        return "@/assets/img/secure-off-fill.svg";
      }

      if (this.lockStatus === "unlocking") {
        return "@/assets/img/more.svg";
      }

      return "";
    },
  },

  methods: {
    onClose() {
      window.call.hideCall();
    },

    decline() {
      this.onClose();
      this.$emit("decline");
    },

    unlockHandler() {
      if (this.canUnlock) {
        this.$emit("unlock");
      }
    },
  },
};
</script>

<style lang="scss">
#publisher-div {
  display: none;
}

.video-modal {
  background: #000;
}

.call-toolbar {
  position: absolute;
  z-index: 999;
  bottom: 70px;
  left: 50%;
  transform: translate(-50%, 0);
  &--status {
    margin-bottom: 30px;
  }
  &--spinner.lds-ring {
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 15px;
    div {
      border-color: #fff transparent transparent transparent;
    }
  }
  &--icon {
    width: 2em;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  &--text {
    color: #fff;
    font-size: 18px;
  }

  .end-call {
    width: 3rem;
    height: 3rem;
    position: relative;
    border-radius: 50%;
    background: var(--danger);
    border: none;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      width: 60%;
      height: auto;
      object-fit: contain;
    }
  }
}
.video-modal {
  position: relative;
  border-radius: var(--modal-border-radius);
}
video {
  width: 100%;
  min-height: 600px;
  position: relative;
  border-radius: var(--modal-border-radius);
  margin-bottom: 30px;
  overflow: hidden;
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 9;
    background: linear-gradient(
      0.01deg,
      rgba(0, 0, 0, 0.62) 26.76%,
      rgba(0, 0, 0, 0) 81.02%
    );
  }
  & > *,
  .OT_subscriber {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}
.video-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.unlock-button {
  display: flex;
  align-items: center;
  padding: 1em 2em;
  font-size: 15px !important;
  border: none;
  outline: none;
  box-shadow: none;
  margin-left: auto;
  margin-right: auto;

  z-index: 9999;

  border-radius: 30px;
  background: #fff;
  color: var(--green);
  width: max-content;
  margin-bottom: 40px;

  @include media($desktop) {
    transform: translate(-50%, 50%);
    position: absolute;
    bottom: -60px;
    left: 50%;
    margin-bottom: 0;
  }
  &:not([disabled="true"]) {
    cursor: pointer;
  }

  &[disabled] {
    opacity: 1;
    .unlock-button-text,
    .unlock-button-icon {
      opacity: 0.5;
    }
  }

  img {
    width: 1em;
    height: auto;
    font-size: inherit;
  }

  &-icon {
    font-size: inherit;
  }

  &-text {
    display: block;
    margin-right: 2em;
    font-size: inherit;
  }
}
</style>
