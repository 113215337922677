<template>
  <CardContent
    title="Hello"
    description="Lets verify your <br> account"
    :icon="require('@/assets/img/login.png')"
  >
    <form @submit.prevent="submitHandler" class="login-form" novalidate>
      <div class="select-auth-type">
        <h3>Choose what to start with</h3>

        <RadioButton
          v-for="(type, idx) of authTypes"
          :key="type.type"
          :id="`auth/${type.type}`"
          :label="type.label"
          :checked="idx === 0"
          :value="type.value"
          name="auth-type"
          class="select-auth-type--radio"
          v-model="authType"
        />
      </div>

      <FormValidateProvider
        :validate="needValidate"
        :parentErrors="errors"
        @validate="validationHandler"
        @error="errorHandler"
      >
        <div v-if="authType === 'email'">
          <ValidateProvider :rules="['required', 'email']">
            <AppInput
              label="Enter your email address"
              description="Verification code will be sent to your e-mail"
              id="user-email"
              name="login-email"
              class="w-100"
              v-model="email"
              type="email"
              :parentError="apiError"
            />
          </ValidateProvider>
        </div>

        <div v-else-if="authType === 'phone'">
          <ValidateProvider
            :rules="['required', 'phone', ['minLength', 8], ['maxLength', 11]]"
          >
            <AppInput
              label="Enter your phone number"
              description="Verification code will be sent to your phone number"
              id="user-phone"
              name="login-phone"
              class="w-100"
              v-model="phone"
              type="tel"
              field-type="phone"
              :parentError="apiError"
            />
          </ValidateProvider>
        </div>
      </FormValidateProvider>

      <AppButton type="submit" color="green" size="fluid" :loading="loading">
        Continue
      </AppButton>
    </form>
  </CardContent>
</template>

<script>
import { mapGetters } from "vuex";
import { useForm } from "@/mixins/useForm";
import FormValidateProvider from "@/components/UI/FormValidateProvider";
import ValidateProvider from "@/components/UI/ValidateProvider";
import RadioButton from "@/components/UI/RadioButton.vue";
import AppInput from "@/components/UI/AppInput";
import AppButton from "@/components/UI/AppButton";
import CardContent from "@/components/Сard/CardContent";

const authTypes = {
  email: "email",
  phone: "phone",
};

export default {
  name: "AuthEmail",

  components: {
    CardContent,
    AppButton,
    AppInput,
    ValidateProvider,
    FormValidateProvider,
    RadioButton,
  },

  data() {
    return {
      authTypes: Object.entries(authTypes).map(([key, value]) => {
        return {
          type: key,
          value,
          label: key === "phone" ? "Phone Number" : key,
        };
      }),
      phone: "",
      email: "",
      loading: false,
      apiError: null,
      authType: authTypes.email,
      errors: [],
    };
  },

  computed: {
    ...mapGetters({
      isInitialized: "isInitialized",
    }),
  },

  mixins: [useForm],

  mounted() {
    this.$store.dispatch("init");
  },

  watch: {
    authType() {
      this.errors = [];
      this.apiError = null;
      this.phone = "";
      this.email = "";
    },
  },

  methods: {
    errorHandler(err) {
      this.errors.push(err);
    },
    async onSubmit() {
      this.loading = true;

      try {
        const { error } = await this.$store.dispatch(
          "sendCode",
          this.email || this.phone
        );

        this.loading = false;

        if (error) {
          this.apiError = `${error.message}. Try again later.`;
          return;
        }

        await this.$router.push({ path: "/auth/verification" });
      } catch (e) {
        console.log(e);
        this.apiError = e;
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-auth-type {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 40px;
  margin-bottom: 40px;
  position: relative;

  @media (max-width: 768px) {
    @media (min-height: 700px) {
      margin-top: 40px;
    }
  }

  &:before {
    content: "";
    width: calc(100% + var(--x) * 2);
    height: 1px;
    background: #f3f3f3;
    position: absolute;
    bottom: 0;
    left: calc(var(--x) * -1);
  }

  h3 {
    width: 100%;
    margin-bottom: 28px;
  }

  &--radio {
    &:not(:last-child) {
      margin-right: 40px;
    }
  }
}
</style>
