<template>
  <AppModal
    :opened="opened"
    @close="close"
    :width="400"
    :close-on-backdrop="false"
  >
    <h2 class="text-center incoming-from" v-if="callModel.from">
      {{ callModel.from }}
    </h2>

    <p>Incoming call</p>

    <div class="call-alert-action__group">
      <button
        class="call-alert-action call-alert-action__decline"
        @click="decline"
      >
        <img src="@/assets/img/icons/call-decline.svg" alt="Decline" />
        <span>Decline</span>
      </button>

      <button
        class="call-alert-action call-alert-action__accept"
        @click="accept"
      >
        <img src="@/assets/img/icons/call-accept.svg" alt="Accept" />
        <span>Accept</span>
      </button>
    </div>
  </AppModal>
</template>

<script>
import AppModal from "../UI/AppModal.vue";
export default {
  name: "CallAlert",
  components: { AppModal },
  emits: ["close"],

  data() {
    return {
      callModel: window.call.alertData || {},
    };
  },

  computed: {
    opened() {
      return this.$store.getters.callAlertShown;
    },
  },

  methods: {
    close() {
      this.$store.commit("setCallAlertShown", false);
    },

    accept() {
      this.close();
      window.call.showCall();
    },

    decline() {
      this.close();
    },
  },
};
</script>
<style lang="scss">
.incoming-from + p {
  margin-top: 0.7em;
}

.call-alert-action {
  width: 4rem;
  height: 4rem;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0 5px;
  cursor: pointer;
  display: block;
  position: relative;
  transition: 0.2s;
  transform: translateY(0);

  &:hover {
    transform: translateY(-5px);
  }

  &:not(:last-child) {
    margin-right: 25px;
  }

  span {
    font-size: 14px;
    display: block;
    margin-top: 10px;
  }

  &:focus {
    outline: none;
  }

  img {
    width: 70%;
    height: auto;
    margin: 0 auto;
  }

  &__group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 30px;
  }
}
</style>
