import axios from "axios";
import { apiRoutes } from "@/api/api-routes";

export function createAxios(store, { logout }) {
  const $axios = axios.create({
    baseURL: "https://www.interqr.com/api",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      "Referrer-Policy": "no-referrer",
    },
  });

  $axios.interceptors.request.use(
    async (req) => {
      const withAuthRequests = Object.values(apiRoutes)
        .filter(({ auth }) => auth)
        .map(({ route }) => route());

      const url = req.url.slice(0, 6);

      const needToken = withAuthRequests.some((request) =>
        request.includes(url)
      );

      if (needToken) {
        const { token } = store.getters;

        req.headers.Authorization = `Bearer ${token}`;
      }

      return req;
    },
    (err) => Promise.reject(err)
  );

  $axios.interceptors.response.use(
    undefined,
    async function axiosRetryInterceptor(err) {
      if (err?.response?.data?.error?.toLowerCase() === "unauthorized") {
        await logout();
      }

      return Promise.reject(err);
    }
  );

  return $axios;
}
