<template>
  <div class="modal" :class="{ opened: opened }">
    <div class="modal-overlay" @click="closeOnBackdrop && closeHandler()" />

    <div class="modal-window" :style="style">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppModal",
  emits: {
    close: null,
  },

  props: {
    opened: { type: Boolean, default: false },
    width: { type: Number, default: 370 },
    usePadding: { type: Boolean, default: true },
    bg: { type: Boolean, default: true },
    closeOnBackdrop: { type: Boolean, default: true },
  },

  computed: {
    style() {
      const styles = {
        width: `${this.width}px`,
      };

      if (!this.usePadding) {
        styles.padding = "0 0 30px 0 !important";
      }

      if (!this.bg) {
        styles.background = "transparent !important";
      }

      return styles;
    },
  },

  methods: {
    closeHandler() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
:root {
  --modal-border-radius: 27px;
}
.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  overflow: auto;

  width: 100%;
  height: 100%;
  z-index: 999999999999;

  h3 {
    font-size: 1.3rem;
    @include media(md) {
      padding-top: 0.5em;
    }
  }

  &:not(.opened) {
    pointer-events: none;
    .modal-overlay {
      opacity: 0;
    }
    .modal-window {
      opacity: 0;
      transform: translateY(40px);
    }
  }

  &-overlay {
    background-color: rgba(#000, 0.4);
    @include media($desktop, "max") {
      backdrop-filter: blur(10px);
    }

    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.35s;
  }

  &-window {
    height: auto;
    max-width: calc(100% - 40px);
    background: #ffffff;
    border-radius: var(--modal-border-radius);
    z-index: 999;
    transition: 0.35s;
    overflow: hidden;

    padding: 30px 20px 10px 20px;
    text-align: center;
    @include media($desktop) {
      padding: 40px 40px 20px 40px;
    }
  }
}
</style>
