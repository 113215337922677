export const useForm = {
  emits: {
    submit: null,
  },
  data() {
    return {
      isValid: true,
      needValidate: false,
    };
  },
  methods: {
    validationHandler(valid) {
      this.isValid = valid;
    },

    submitHandler() {
      this.needValidate = true;

      this.$nextTick(() => {
        if (this.isValid) {
          this.onSubmit();
        }
      });
    },
  },
};
