<template>
  <slot />
</template>

<script>
import { computed } from "vue";

export default {
  name: "FormValidateProvider",

  props: {
    validate: {
      type: Boolean,
      default: false,
    },

    parentErrors: { type: Array, default: () => [] },
  },

  emits: {
    validate: null,
    error: null,
  },

  data() {
    return {
      errors: this.parentErrors,
    };
  },

  computed: {
    hasError() {
      return !!this.errors.length;
    },
  },

  watch: {
    hasError(err) {
      this.$emit("validate", !err);
    },

    parentErrors(errors) {
      this.errors = errors;
    },
  },

  methods: {
    errorHandler(error) {
      this.$emit("error", error);
      this.errors.push(error);
    },

    validHandler(id) {
      this.errors = this.errors.filter((err) => err.id !== id);
    },
  },

  provide() {
    return {
      onError: this.errorHandler,
      onValid: this.validHandler,
      checkValidate: computed(() => this.validate),
    };
  },
};
</script>

<style scoped></style>
