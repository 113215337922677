import { createStore } from "vuex";
import { authModule } from "@/store/auth";
import { api } from "@/main.js";
import call from "@/store/call";
export default createStore({
  state: {
    locks: [],
    workStatus: "stopped",
    route: "",
    sockets: [],
  },

  getters: {
    currentRoute(state) {
      return state.route;
    },
    workStatus(state) {
      return state.workStatus;
    },

    locks(state) {
      return state.locks;
    },

    sockets(state) {
      return state.sockets;
    },
  },

  mutations: {
    setCurrentRoute(state, route) {
      state.route = route;
    },

    setLocks(state, locks) {
      state.locks = locks;
    },

    unLock(state, { id, status }) {
      const lock_uuid = state.locks.find((lock) => lock.lock_uuid === id);
      if (lock_uuid) {
        lock_uuid.status = status;
      }
    },

    setWorkStatus(state, status) {
      state.workStatus = status;
    },

    addSocket(state, socket) {
      state.sockets.push(socket);
    },

    clearSockets(state) {
      state.sockets = [];
    },

    removeSocket(state, socket) {
      state.sockets = state.sockets.filter(({ url }) => url !== socket.url);
    },
  },

  actions: {
    async fetchLocks({ commit, getters }) {
      const { token } = getters;

      if (!token) return;

      try {
        const { data } = await api.fetchUserDetails(token);
        if (!data) {
          return;
        }
        const available = data.available ? parseInt(data.available) : 0;
        commit("setWorkStatus", available === 1 ? "started" : "stopped");

        const locks = data?.locks;
        if (locks) {
          commit(
            "setLocks",
            locks.map((lock) => ({ ...lock, status: "locked" }))
          );
        }

        return !!locks;
      } catch (e) {
        console.error(e);
      }
    },

    updateWorkStatus({ commit }, status = true) {
      let __status = status ? "started" : "stopped";

      return new Promise((res, reject) => {
        api
          .toggleWorkStatus(__status)
          .then(() => {
            commit("setWorkStatus", __status);
            res();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    async unLock({ commit, getters }, id) {
      commit("unLock", { id, status: "unlocking" });
      const status = await api.unlock(id, getters.token);
      if (!status) return;

      commit("unLock", { id, status: "unlocked" });

      await setTimeout(() => {
        commit("unLock", { id, status: "locked" });
      }, 5000);
    },
  },
  modules: {
    auth: authModule,
    call,
  },
});
