<template>
  <AppModal :opened="logoutConfirmOpened" @close="logoutConfirmOpened = false">
    <div class="confirm-modal">
      <h2 class="text-center">
        Are you sure <br />
        want to logout?
      </h2>

      <div class="button-group">
        <AppButton size="fluid" color="danger" @click="logoutHandler">
          Yes, logout
        </AppButton>
        <AppButton
          size="fluid"
          color="light"
          @click="logoutConfirmOpened = false"
        >
          Cancel
        </AppButton>
      </div>
    </div>
  </AppModal>

  <CallAlert />
  <CallModal :lockStatus="doorStatus" @unlock="unlockHandler" />

  <div class="bg-container">
    <picture>
      <source srcset="@/assets/img/main-bg.jpg" media="(min-width: 768px)" />
      <img src="#" alt="" />
    </picture>
    <div class="main-layout">
      <header>
        <div class="row w-100">
          <div class="logo-wrapper">
            <router-link to="/" class="logo">
              <img src="@/assets/img/logo.png" alt="InterQR" />
            </router-link>
          </div>
          <div class="col-8 toolbar" v-if="isAuthenticated">
            <div class="row">
              <button
                :class="`${workStatus}`"
                class="work-status"
                @click="changeWorkStatus"
              >
                {{ statusButtonText }}
              </button>

              <AppButton
                size="normal"
                color="danger"
                @click="logoutConfirmOpened = true"
              >
                Logout
              </AppButton>
            </div>
          </div>
        </div>
      </header>
      <main>
        <slot />
      </main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AppButton from "@/components/UI/AppButton";
import AppModal from "@/components/UI/AppModal";
import CallModal from "@/components/Calls/CallModal";
import CallAlert from "@/components/Calls/CallAlert.vue";
export default {
  name: "Layout",
  components: { CallModal, AppModal, AppButton, CallAlert },
  data() {
    return {
      logoutConfirmOpened: false,
      declineCall: null,
      doorStatus: "locked",
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated", "workStatus", "currentRoute"]),
    statusButtonText() {
      return this.workStatus === "started" ? "Pause work" : "Start work";
    },
  },

  methods: {
    ...mapActions(["updateWorkStatus", "logout"]),

    changeWorkStatus() {
      let status = this.workStatus === "stopped";
      this.updateWorkStatus(status);
    },

    logoutHandler() {
      this.logoutConfirmOpened = false;
      this.logout().then();
      this.$router.push({ path: "/auth" });
    },

    async unlockHandler() {
      this.doorStatus = "unlocking";

      await this.$store.dispatch("unlockFromSocket");

      this.doorStatus = "unlocked";
      setTimeout(() => {
        this.doorStatus = "locked";
      }, 5000);
    },
  },
};
</script>

<style lang="scss">
.pt-4 {
  padding-top: 40px;
}
.bg-container {
  width: 100%;
  @include media($desktop) {
    position: relative;
    padding-bottom: 50px;
    & > picture {
      width: 100%;
      height: 100%;
      object-fit: cover;

      position: absolute;

      top: 0;
      left: 0;
      z-index: -1;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        position: absolute;

        top: 0;
        left: 0;
        z-index: -1;
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        backdrop-filter: blur(20px);
      }
    }
  }
}
body {
  background-color: #fcfcfb;
}
.confirm-modal {
  .button-group {
    margin-top: 35px;
  }
  h2 {
    font-size: 25px;
  }
  @include media(xxl) {
    button {
      font-size: 18px;
    }
  }
}
.main-layout {
  --header-height: 80px;
  width: 100%;
  max-width: 35rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: var(--header-height);

  overflow: hidden;
  @include media($desktop) {
    --header-height: 110px;
    padding-left: 0;
    padding-right: 0;
    overflow: visible;
  }

  main {
    padding-top: 10px;
    @include media($desktop) {
      padding-top: 30px;
    }
    position: relative;
  }
}

.logo {
  display: block;
  width: 85px;
  @media (max-width: 360px) {
    width: 67px;
  }
  @include media($desktop) {
    width: 100px;
  }
}

.logo-wrapper {
  min-width: 85px;
  @include media($desktop) {
    min-width: 100px;
  }
}

header {
  height: var(--header-height);
  position: absolute;
  top: 0;
  left: 0;
  padding-left: var(--layout-x);
  padding-right: var(--layout-x);
  display: flex;
  width: 100%;
  box-sizing: border-box;
  z-index: 999;

  @include media($desktop) {
    box-shadow: var(--card-shadow);
    background: #fff;
    border-radius: var(--border-radius);

    padding: 0 55px;
    --header-height: 100px;
    margin-top: 20px;
  }

  @include media(xxl) {
  }
  & > * {
    flex: 1;
  }
}

.toolbar {
  flex: 1;

  .row {
    justify-content: flex-end;
  }

  .button-color-danger {
    @include media($desktop, "max") {
      background-color: transparent !important;
      padding: 1em 1em;
      @media (max-width: 360px) {
        font-size: 15px;
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }
  }

  .work-status {
    background: transparent;
    box-shadow: none;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: 700;
    margin-right: 20px;
    padding: 10px 0;
    cursor: pointer;
    position: relative;

    @media (any-hover: hover) {
      transition: color 0.3s;
      &:hover {
        color: var(--green);
      }
    }

    &:before {
      content: "";
      font-size: 0.55em;
      width: 1em;
      height: 1em;
      border-radius: 50%;
      display: block;
      transition: 0.3s;

      position: absolute;
      top: 50%;
      left: 0;

      transform: translate(calc(-100% - 8px), -50%);

      @include media($desktop) {
        transform: translate(calc(-100% - 13px), -50%);
      }
    }

    &.stopped {
      &:before {
        background: var(--dark-grey);
      }
    }
    &.started {
      &:before {
        background: var(--green);
      }
    }

    @include media($desktop) {
      margin-right: 35px;
    }
  }
}
</style>
