<template>
  <div class="card" v-bind="$attrs">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Card",
};
</script>

<style scoped lang="scss">
.card {
  --image-width: 10rem;
  padding: 0 0 calc(var(--y) / 2) 0;
  border-radius: 31px;
  overflow: hidden;
  transition: height 0.25s;
  @include media($desktop) {
    padding-top: var(--y);
    padding-bottom: var(--y);
    background: #fff;
    box-shadow: var(--card-shadow);
    --image-width: 11rem;
  }
}
</style>
