<template>
  <label :for="id" class="radio-button">
    <input
      type="radio"
      @change="changeHandler"
      :id="id"
      v-bind="$attrs"
      class="radio-button-input"
    />

    <span class="radio-button-button" />

    <span class="radio-button-label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "RadioButton",
  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
  },

  emits: {
    "update:modelValue": null,
  },

  methods: {
    changeHandler({ target }) {
      console.log("change", target.value);
      this.$emit("update:modelValue", target.value);
    },
  },
};
</script>

<style lang="scss">
.radio-button {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid var(--green);
    display: block;
    margin-right: 10px;
    position: relative;
    transition: background 0.2s;
    &:before {
      content: "";
      width: 55%;
      height: 55%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &-label {
    text-transform: capitalize;
    color: #b9b9b9;
  }

  input {
    visibility: hidden;
    position: absolute;

    &:checked {
      & ~ .radio-button-button {
        background: var(--green);
        &:before {
          opacity: 1;
        }
      }

      & ~ .radio-button-label {
        color: inherit;
      }
    }
  }
}
</style>
