import { api } from "@/main.js";
import { storage } from "@/storage/storage";

export const authModule = {
  state: {
    isInitialized: false,
    isAuthenticated: false,
    client_uuid: null,
    token: null,
    refreshToken: null,
    email: null,
  },

  getters: {
    client_uuid(state) {
      return state.client_uuid;
    },
    email(state) {
      return state.email;
    },

    isAuthenticated(state) {
      return state.isAuthenticated;
    },

    isInitialized(state) {
      return state.isInitialized;
    },

    token(state) {
      return state.token;
    },
  },

  mutations: {
    setEmail(state, email) {
      state.email = email;
    },

    initialize(state, isInitialized = true) {
      state.isInitialized = isInitialized;
    },

    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },

    setAuthData(state, { token, uuid }) {
      state.token = token;
      state.serverUuid = uuid;
      state.isAuthenticated = true;
    },

    setToken(state, token) {
      if (token) {
        storage.save({ token: token });
      } else {
        storage.delete("token");
      }

      state.token = token;
    },

    setClientUuid(state, uuid) {
      storage.save({ client_uuid: uuid });
      state.client_uuid = uuid;
    },

    setAuthStatus(state) {
      state.isAuthenticated = true;
    },
  },

  actions: {
    init({ commit, getters }) {
      return new Promise((resolve, reject) => {
        api
          .init(getters.client_uuid)
          .then(() => {
            commit("initialize");
          })
          .catch((err) => reject(err));
      });
    },

    async sendCode({ commit }, email) {
      try {
        const status = await api.sendCode(email);
        if (status) {
          commit("setEmail", email);
          return true;
        }
      } catch (e) {
        console.error(e);
        return { error: e };
      }
    },

    async verifyCode({ commit, getters }, code) {
      const { email, client_uuid } = getters;

      try {
        const { data = {} } = await api.verifyCode(email, code);

        if (data && data.token) {
          commit("setToken", data.token);
          const result = await api.login(client_uuid);

          if (result) {
            commit("setAuthData", data);

            return true;
          }

          return false;
        }

        return false;
      } catch (e) {
        console.error(e);
      }
    },

    async logout({ commit, getters }) {
      const { client_uuid } = getters;
      await api.logout(client_uuid);

      commit("setToken", null);
      commit("setAuthenticated", false);
    },
  },
};
