<template>
  <div class="card-content">
    <div class="card--heading">
      <IconButton
        :icon="require('@/assets/img/icons/back.svg')"
        @click="back"
        v-if="back"
        class="card-back"
      />

      <div class="card-icon" v-if="icon">
        <img :src="icon" alt="" />
      </div>
      <div>
        <h1 v-html="title" />
        <p v-if="description" v-html="description" />
      </div>
    </div>

    <div class="card--body">
      <slot />
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/UI/IconButton";
export default {
  name: "CardContent",
  components: { IconButton },
  props: {
    icon: { type: [String, null], default: null },
    title: { type: String, required: true },
    description: { type: [String, null], default: null },
    back: { type: Function, required: false },
  },

  computed: {
    iconPath() {
      if (!this.icon) {
        return null;
      }

      return require(this.icon);
    },
  },
};
</script>

<style scoped lang="scss">
.card-back {
  //margin-bottom: 50px;
  margin-bottom: 25px;
  @include media(md) {
  }
}

.card-content {
  position: relative;
}
.card {
  position: relative;

  &--heading,
  &--body {
    position: relative;
    z-index: 1;
    padding-left: var(--layout-x);
    padding-right: var(--layout-x);
    @include media($desktop) {
      padding: 0 var(--x);
    }
  }

  &--heading {
    padding-top: 30px;
    h1,
    p {
      position: relative;
      z-index: 2;
    }

    @include media($desktop) {
      padding-top: 0;
    }
  }

  &--body {
    z-index: 2;
    padding-left: var(--layout-x);
    padding-right: var(--layout-x);
    @include media($desktop) {
      padding: 0 var(--x);
    }
  }

  &-icon {
    position: absolute;
    right: calc((var(--layout-x) * -1) * 1.1);
    top: 0;
    z-index: 1;

    width: var(--image-width);

    @include media(sm) {
      right: 0;
    }
    @include media($desktop) {
      right: var(--x);
    }
  }
}

.card-content-verification {
  .card--heading {
    padding-top: 0;
  }
  .card-icon {
    @include media($desktop, "max") {
      --image-width: 7rem;
      right: 0;
      @media (min-height: 660px) {
        --image-width: 8rem;
        position: static;
      }
    }
  }
}

// .card-content {
//   &:not(.card-content-home) {
//     .card-icon {
//       @include media($desktop, "max") {
//         @media (min-height: 700px) {
//           --image-width: 8rem;
//           position: static;
//           margin-bottom: 30px;
//         }
//       }
//     }
//   }
// }
.card-content-home {
  .card-icon {
    --image-width: 9.5rem;
  }

  @include media($desktop, "max") {
    padding-top: 20px;
    .card-icon {
      right: 10px;
    }
  }

  @include media($desktop) {
    h1 {
      margin-top: 20px;
    }
    .card-icon {
      --image-width: 10rem;
      top: -30px;
    }
  }
}
</style>
