<template>
  <Layout>
    <Card>
      <div class="route-root">
        <router-view v-slot="{ Component }">
          <transition
            :css="false"
            @before-enter="beforeEnter"
            @enter="enter"
            @leave="leave"
          >
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </Card>
  </Layout>
</template>
<script>
import gsap from "gsap";
import Layout from "@/components/Layout/Layout";
import Card from "@/components/Сard/Card";
import CallModal from "./components/Calls/CallModal.vue";
import CallAlert from "./components/Calls/CallAlert.vue";

const animationDuration = 0.4;

export default {
  components: { Card, Layout, CallModal, CallAlert },
  data() {
    return {
      routeHeight: "auto",
      heightController: null,
    };
  },

  methods: {
    beforeEnter(el) {
      gsap.set(el, {
        xPercent: -100,
        autoAlpha: 0,
        position: "absolute",
        width: "100%",
      });
    },

    enter(el, done) {
      setTimeout(() => {
        gsap.set(el, { position: "relative" });
      }, animationDuration / 2);

      if (window.matchMedia("(min-width: 769px)").matches) {
        gsap.set(el, { height: 400 });
        gsap.to(el, { height: "auto" });
      }

      gsap.to(el, {
        xPercent: 0,
        autoAlpha: 1,
        duration: animationDuration,
        delay: animationDuration * 0.5,

        onComplete: () => {
          done();
        },
      });
    },

    leave(el, done) {
      setTimeout(() => {
        gsap.set(el, { position: "absolute", width: "100%" });
      }, animationDuration / 2);

      gsap.to(el, {
        xPercent: 100,
        autoAlpha: 0,
        duration: animationDuration,
        onComplete: done,
      });
    },
  },
};
</script>

<style lang="scss">
.height-controller {
  transition: height 0.3s;
  width: 100%;
}
#app {
  min-height: 100vh;
  display: flex;
  @include media($desktop, "max") {
    .main-layout {
      display: flex;
      flex-direction: column;
    }
    .card-body,
    main,
    .card,
    .route-root {
      flex: 1 !important;
    }

    .route-root,
    main {
      display: flex;
    }
    .bg-container {
      display: flex;
    }
    .card {
      display: flex;

      .card--body {
        flex: 1;
        display: flex;
        .login-form {
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: space-between;
        }
      }
    }
    & > * {
      flex: 1;
      & > * {
        display: flex;
        flex-direction: column;
        .route-root {
          display: flex;
        }
        .card-content {
          flex: 1;
          min-height: 100%;

          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
.overflow {
  position: relative;
  overflow: hidden;
}

.route-root {
  position: relative;
  transition: 0.3s 0s;
}
.d-flex {
  display: flex;
  & > * {
    width: 100%;
  }
}
</style>
