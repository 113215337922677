export const apiRoutes = {
  init: {
    route() {
      return "/init";
    },
    auth: false,
  },

  sendCode: {
    route() {
      return "twofa/start";
    },
    auth: false,
  },

  verifyCode: {
    route() {
      return "twofa/verify";
    },
    auth: false,
  },

  userDetails: {
    route() {
      return "/resource/user/details";
    },
    auth: true,
  },

  login: {
    route() {
      return "/login";
    },
    auth: true,
  },

  unlock: {
    route(id) {
      return `locks/${id}/unlock`;
    },
    auth: true,
  },

  logout: {
    route() {
      return `/logout`;
    },
    auth: true,
  },

  pushSubscribe: {
    route() {
      return "/notifications/push/sub";
    },
    auth: true,
  },

  toggleWorkStatus: {
    route(status) {
      return `/set-available/${status}`;
    },
    auth: true,
  },

  unlockBySession: {
    route(sessionId) {
      return `/sessions/${sessionId}/unlock`;
    },

    auth: true,
  },
};
