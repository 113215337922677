<template>
  <div
    class="door"
    :class="{ [status]: true, disabled: buttonDisabled }"
    @click="$emit('unlock')"
  >
    <div class="door--body">
      <div class="door-left">
        <div class="door-secure">
          <transition name="fade">
            <img :src="secureIcon" alt="Locked" />
          </transition>
        </div>

        <div class="door-info">
          <h3>{{ title }}</h3>
          <p v-if="description">{{ description }}</p>
        </div>
      </div>

      <div class="door-right">
        <div class="door-status">
          <img :src="statusIcon" alt="" v-if="status !== 'unlocking'" />
          <Spinner v-else />
        </div>
      </div>
    </div>

    <div class="door--footer">
      <button class="door-action-button" :disabled="buttonDisabled">
        <transition name="fade">
          {{ buttonText }}
        </transition>
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Door/Spinner";
export default {
  name: "Door",
  components: { Spinner },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: false },
    status: { type: String, default: "locked" },
  },

  computed: {
    buttonDisabled() {
      return this.status !== "locked";
    },
    secureIcon() {
      if (this.status === "locked") {
        return require("@/assets/img/secure-on.svg");
      }
      if (this.status === "unlocked") {
        return require("@/assets/img/secure-off.svg");
      }

      if (this.status === "unlocking") {
        return require("@/assets/img/more.svg");
      }

      return "";
    },

    statusIcon() {
      return require(`@/assets/img/${this.status}.svg`);
    },

    buttonText() {
      let text = "Locked";

      if (this.status === "unlocked") {
        text = "Unlocked";
      }

      if (this.status === "unlocking") {
        text = "Unlocking...";
      }

      return text;
    },
  },
};
</script>

<style scoped lang="scss">
.door {
  --padding: 20px 23px;
  border: 1px solid var(--stroke-grey);
  border-radius: var(--border-radius);
  padding: var(--padding);
  cursor: pointer;
  max-width: 100%;
  overflow: hidden;
  box-shadow: 0 0 25px rgba(#000, 0);

  &.disabled {
    cursor: default;
    pointer-events: none;
  }
  @media (any-hover: hover) {
    transition: 0.3s;

    &:hover {
      border-color: transparent;
      background: #fff;
      box-shadow: 0 15px 25px rgba(#000, 0.07);
      transform: scale(1.01);
    }
  }
  &-secure {
    width: 41px;
    min-width: 41px;
    height: 41px;
    position: relative;
    border-radius: 50%;
    transition: 0.3s;

    img {
      width: 50%;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-status {
    width: 38px;
    height: 42px;
    margin-top: 5px;

    img {
      width: 100%;
    }
  }

  &--body {
    display: flex;
  }

  &--footer {
    margin-top: 25px;
  }

  &-left {
    display: flex;
    align-items: center;
    flex: 1;
    max-width: calc(100% - 38px);
    @include media($desktop) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-right {
    width: 38px;
  }

  &-info {
    margin-left: 12px;
    overflow: hidden;
    h3 {
      font-size: 1rem;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 15px;
    }
    p {
      font-size: 0.83rem;
      opacity: 0.5;
      margin-top: 3px;
    }
    @include media($desktop) {
      margin-left: 0;
      margin-top: 12px;
    }
  }

  &-action-button {
    background-color: transparent;
    box-shadow: none;
    border: none;
    margin: 15px auto 0 auto;
    display: block;
    font-size: 15px;
    font-weight: 700;
    color: var(--green);
    &:disabled {
      opacity: 0.5;
    }
    &:not(:disabled) {
      cursor: pointer;
    }
  }

  &.locked {
    .door-secure {
      background: var(--green-gradient);
      box-shadow: 0 10px 25px rgba(47, 191, 84, 0.1);
    }
  }
  &.unlocking {
    .door-secure {
      background: var(--dark-grey);
    }
  }
  &.unlocked {
    .door-secure {
      background: var(--yellow-gradient);
      box-shadow: 0 10px 25px rgba(#efa83d, 0.1);
    }
  }
}
</style>
