<template>
  <button
    class="button"
    v-bind="$attrs"
    :ref="setRef"
    :disabled="loading"
    :class="{
      [`button-color-${color}`]: true,
      [`button-size-${size}`]: true,
    }"
  >
    <span v-if="loading"><Spinner /></span>
    <slot v-else />
  </button>
</template>

<script>
import Spinner from "@/components/Door/Spinner";
export default {
  name: "AppButton",
  components: { Spinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "green",
      validator: (color) => ["green", "danger", "light"].indexOf(color) !== -1,
    },

    size: {
      type: String,
      default: "normal",
      validator: (size) => ["normal", "fluid"].indexOf(size) !== -1,
    },

    setRef: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss">
.button {
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: var(--border-radius);
  padding: 1.15em 3.7em;
  font-weight: 700;

  &[disabled] {
    opacity: 0.8;
    pointer-events: none;
  }

  &:not(.button-color-light) {
    box-shadow: 0 20px 20px rgba(#005298, 0.1);
    transition: 0.3s;
    &:focus {
      transform: translateY(-5px);
      box-shadow: 0 25px 20px rgba(#005298, 0.1);
    }
    @media (any-hover: hover) {
      &:hover {
        transform: scale(1.02) translateY(-5px);
        box-shadow: 0 25px 20px rgba(#005298, 0.15);
      }
    }

    @media (any-hover: none) {
      &:focus {
        transform: scale(1.01) translateY(-5px);
        box-shadow: 0 25px 20px rgba(#31c155, 0.2);
      }
    }

    &:active {
      transform: scale(1, 1) translateY(-3px);
      box-shadow: 0 20px 20px rgba(#31c155, 0.1);
    }
  }
  &-size {
    &-normal {
      font-size: 15px;
      width: max-content;
    }

    &-fluid {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  &-color {
    &-green {
      background: var(--green-gradient);
      color: #fff;

      .lds-ring div {
        border-color: #fff transparent transparent transparent;
      }
    }
    &-light {
      color: var(--dark-grey);
    }
    &-danger {
      background-color: var(--danger-light);
      color: var(--danger);
      box-shadow: none !important;
      &:hover,
      &:active,
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}
</style>
