class Storage {
  get(data) {
    try {
      return localStorage.getItem(data);
    } catch (e) {
      console.error(e);
    }
  }

  save(data) {
    Object.entries(data).forEach(([key, value]) => {
      if (value) {
        localStorage.setItem(key, value.toString());
      }
    });
  }

  delete(key) {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }
}

export const storage = new Storage();
