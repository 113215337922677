<template>
  <transition name="fade">
    <div class="overlay" v-if="opened" @click="close" />
  </transition>

  <div class="phone-code-select" tabindex="-1" @click.stop>
    <div class="selected country" @click="open" ref="selectedNode">
      <span class="code-icon">
        <img :src="selected.icon" :alt="selected.title" />
      </span>

      <span class="chevron" :class="{ opened }">
        <img src="@/assets/img/icons/chevron.svg" alt="Open" />
      </span>

      <span class="code-code">{{ selected.code }}</span>
    </div>

    <ul class="phone-codes-list" :class="{ opened }" tabindex="-1">
      <li
        class="phone-codes-list--item"
        :class="{ selected }"
        v-for="{ title, code, selected, icon } of codes"
        :key="code"
        @click="selectHandler(code)"
      >
        <div class="country">
          <span class="code-icon">
            <img :src="icon" :alt="title" />
          </span>

          <span class="code-title">{{ title }}</span>
          <span class="code-code">{{ code }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "PhoneCodeSelect",

  props: {
    parentOpened: { type: Boolean, default: false },
  },

  emits: {
    select: null,
    close: null,
  },

  watch: {
    parentOpened(opened) {
      this.opened = opened;
    },

    selected() {
      this.$nextTick().then(this.setSelectedNodeWidth);
    },
  },

  data() {
    return {
      opened: false,
      width: 0,
      codes: [
        {
          title: "Israel",
          icon: require("@/assets/img/countries/isr.svg"),
          code: "+972",
          selected: true,
        },
        {
          title: "United States",
          icon: require("@/assets/img/countries/usa.svg"),
          code: "+1",
          selected: false,
        },
      ],
    };
  },

  computed: {
    selected() {
      return this.codes.find(({ selected }) => selected);
    },
  },

  mounted() {
    this.setSelectedNodeWidth();
    this.selected.code && this.$emit("select", this.selected.code);
  },

  methods: {
    setSelectedNodeWidth() {
      this.width = this.$refs.selectedNode?.getBoundingClientRect().width;
    },
    open() {
      this.opened = true;
    },

    close() {
      this.opened = false;
      this.$emit("close");
    },

    selectHandler(code) {
      this.codes.forEach((c) => {
        c.selected = c.code === code;
      });

      this.$emit("select", code);
    },
  },
};
</script>

<style lang="scss">
:root {
  --phone-select-height: 55px;
}
.overlay {
  position: fixed;
  top: calc(var(--y) * -1);
  left: 0;
  transform: translate3d(0, 0, 1px);
  width: 100%;
  height: 100vh;
  //background: rgba(#000, 0.4);
  z-index: 9999999;
}

.phone-code-select {
  position: relative;
  height: var(--phone-select-height);
  .chevron {
    width: 12px;
    height: 8px;
    display: block;
    margin: 0 11px;

    transform-origin: center;
    transition: 0.3s;
    &.opened {
      transform: rotate(180deg);
    }
  }
}

.country {
  display: flex;
  align-items: center;

  &.selected {
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;

    .code-code {
      font-weight: 400 !important;
    }
  }
}

.code-code,
.code-title {
  font-size: 18px;
  font-weight: 700;
}

.code-icon {
  width: 1em;
  height: 1em;
  font-size: 24px;
  display: block;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.2));
  }
}

.phone-codes-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  width: 300px;

  background: #fff;
  box-shadow: 0px 5px 80px rgba(0, 0, 0, 0.2);
  border-radius: 15px;

  position: absolute;
  bottom: 100%;
  left: -20px;
  z-index: 99999999;

  transition: all 0.3s;
  transform-origin: bottom center;
  @include media(sm) {
    left: 20px;
  }
  .code-code,
  .code-title {
    font-size: 16px;
    font-weight: 700;
  }

  &:not(.opened) {
    transform: scale(0.6) translateY(10px);
    opacity: 0;
    pointer-events: none;
  }

  &--item {
    padding: 16px;
    cursor: pointer;
    transition: 0.25s;
    background: #fff;
    border-radius: 15px;

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.selected {
      background: rgba(#2ebc51, 0.1);
      .code-title {
        color: #30c054;
      }
    }

    &:hover {
      &:not(.selected) {
        transform: scale(1.04);
        box-shadow: 0px 15px 80px rgba(0, 0, 0, 0.1);
        border-radius: 15px 15px 15px 15px !important;
      }
    }

    .code-icon {
      margin-right: 1em;
    }

    .code-code {
      margin-left: auto;
    }
  }
}
</style>
