<template>
  <slot @change="changeHandler" />
</template>

<script>
import { testRegexp } from "@/utilles/validator/testRegexp";
import { computed } from "vue";
import { messages } from "@/utilles/validator/messages";
import { getId } from "@/utilles/getId";
export default {
  name: "ValidateProvider",
  props: {
    rules: {
      type: Array,
    },
    checkValid: { type: Boolean, default: false },
  },

  inject: ["onError", "onValid", "checkValidate"],

  data() {
    return {
      value: "",
      touched: false,
      error: {},
      errorType: null,
      id: getId(),
    };
  },

  watch: {
    value(val) {
      if (this.touched) {
        this.validate(val);
      }
    },

    error(err) {
      if (typeof err === "string" && this.onError) {
        this.onError({ id: this.id, error: this.errorType });
      } else if (this.onValid) {
        this.onValid(this.id);
      }
    },

    checkValidate: {
      handler({ value: needValidate }) {
        if (needValidate) {
          this.touched = true;
          const status = this.validate();

          if (status && this.onValid) {
            this.onValid(this.id);
          } else if (this.onError) {
            this.onError({ id: this.id, error: this.errorType });
          }
        }
      },

      deep: true,
    },
  },

  computed: {
    needCheckValidate() {
      if (!this.checkValidate || !this.checkValidate.value) {
        return false;
      }

      return this.checkValidate.value;
    },
    updatedRules() {
      return this.rules.filter((rule) => rule !== "required");
    },
  },

  methods: {
    errorHandler(ruleFailed) {
      this.errorType = ruleFailed;
      this.error = messages[ruleFailed];
    },

    validate(value = this.value) {
      if (this.rules.indexOf("required") !== -1) {
        if (!value.trim().length) {
          this.errorHandler("required");
          return;
        }
      }

      const rules = this.updatedRules;

      let err = false;
      let i = 0;

      while (!err && i < rules.length) {
        const rule = rules[i];
        let inValid;
        let errType = rule;

        if (Array.isArray(rule)) {
          const [type, count] = rule;

          errType = type;

          if (type === "minLength") {
            inValid = value.trim().length < count;
          } else if (type === "maxLength") {
            inValid = value.trim().length > count;
          }
        } else {
          inValid = !testRegexp(rule, value);
        }

        if (inValid) {
          err = true;
          this.errorHandler(errType);
        }

        i++;
      }

      if (!err) {
        this.error = {};
        return true;
      }

      return false;
    },

    changeHandler(value) {
      this.value = value;
    },

    focusoutHandler(value = this.value) {
      this.touched = true;
      this.validate(value);
    },
  },

  provide() {
    return {
      validateChange: this.changeHandler,
      validateFocusout: this.focusoutHandler,
      error: computed(() => this.error),
    };
  },
};
</script>

<style scoped></style>
